<template>
  <div class="container">
    <!-- 背景 -->
    <div class="topbg">
      <!-- 通知 -->
      <div class="noti">
        <div class="pic">
          <i>官方通知</i>
        </div>
        <span>下款前以任何理由提前收费，均为诈骗!</span>
      </div>
    </div>
    <!-- 预估金额 -->
    <div class="amountBg">
      <p class="title">{{ this.viewDatas.realName }}!您的初审预估额度为(元):</p>
      <p class="acount">
        <i>¥ </i>
        <span>{{ this.viewDatas.applyAmount }}</span>
      </p>
    </div>
    <!-- 服务提供方 -->
    <div class="company">
      <!-- 公司信息 -->
      <div class="companyInfo">
        <div class="left">
          <img :src="this.viewDatas.companyLogo" class="logo" alt="" />
        </div>
        <div class="right">
          <p>{{ this.viewDatas.companyName }}</p>
          <p class="txt-tags">{{ this.viewDatas.companyTags }}</p>
        </div>
      </div>
      <!-- 分割线 -->
      <p class="line"></p>
      <div class="business">
        <div class="business-tags">
          <img
            src="../../assets/images/match-detail-scly.png"
            referrerpolicy="no-referrer"
            class="tags-icon"
          />
          <div class="tags-txt">
            <h5>{{ this.viewDatas.major }}</h5>
            <p>擅长领域</p>
          </div>
        </div>
        <div class="business-tags uix-number">
          <img
            src="../../assets/images/match-detail-fwrs.png"
            referrerpolicy="no-referrer"
            class="tags-icon"
          />
          <div class="tags-txt">
            <h5 style="font-size: 0.24rem">{{ this.viewDatas.applyNum }}</h5>
            <p>服务人数</p>
          </div>
        </div>
        <div class="business-tags">
          <img
            src="../../assets/images/match-detail-xkl.png"
            referrerpolicy="no-referrer"
            class="tags-icon"
          />
          <div class="tags-txt">
            <h5 class="downRate">{{ this.viewDatas.loanNum }}</h5>
            <p>下款成功率</p>
          </div>
        </div>
      </div>
      <!-- 协议列表 -->
      <div class="descript">
        <i>特别提示:</i>
        <span>
          吉用钱包会根据您的综合资信状况，为您匹配专属贷款服务机构，在咨询前请您务必仔细阅读并知晓
        </span>
        <div class="descript-arrs">
          <span
            v-for="(item, index) in this.contractArrs"
            :key="index"
            @click="handleShowPopout(item.contractName)"
          >
            《{{ item.contractName }}》</span
          >
        </div>
      </div>
    </div>
    <!-- 默认协议(个人信息授权书) -->
    <div class="procol">
      <div class="server-tips ts-ui-x">
        <span>本次贷款服务提供方</span>
      </div>
      <div class="proDiv">
        <div v-html="viewDatas.authContractDetail"></div>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="btn">
      <div class="bun" @click="submitApply">同意协议 领取额度</div>
    </div>

    <!-- 协议弹窗 -->
    <div v-if="isPopoutShow" class="procolBg">
      <div class="content">
        <i class="colse" @click="handleColsePopout()">x</i>
        <h5>《{{ this.contractTitle }}》</h5>
        <div class="content-dt">
          <div class="proText" v-html="this.contractContent"></div>
          <div class="agreeBtn" @click="submitApply">同意并继续</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";
import { applyBus } from "@/mixins/applyBusEntry.js";
export default {
  name: "MatchResDetail",
  mixins: [applyBus],
  data() {
    return {
      form: {
        channelNo: this.$route.query.source, // 渠道标识
        loanAmount: this.$route.query.amount ? this.$route.query.amount : 0, // 借款金额
      }, // 表单信息
      applyId: this.$route.query.applyId, // 申请编号
      orderSn: this.$route.query.orderSn, // 订单编号
      brand: this.$route.query.brand, // 设备型号
      downPageUrl: this.$route.query.downPageUrl, // 进件结果下载页地址
      hasPushSuccess: undefined, // 是否匹配成功
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程的字段1是 0否
      isRedirectWorkWeixinPage: parseInt(
        this.$route.query.isRedirectWorkWeixinPage
      ), // 标识如果不是贷超流程需要用此字段进行判断是否跳转引导企业微信页面中，引导用户用企业微信扫码联系客服1是 0否
      isPopoutShow: false, // 是否显示协议弹框
      productId: 0, // 产品ID
      redirectUrl: "", // 产品跳转地址
      contractArrs: [], // 协议列表
      contractTitle: "", // 协议标题
      contractContent: "", // 协议内容
      loaclTitle: "",
      viewDatas: {
        realName: "", // 姓名
        applyAmount: "", //申请金额
        companyLogo: "", // 公司logo
        companyName: "", // 公司名称
        companyTags: "", // 公司标签
        major: "", // 擅长领域
        applyNum: 0, // 申请人数
        loanNum: "", // 放款人数
        authContractDetail: "", // 个人信息授权书(默认显示)
      }, // 页面显示内容
    };
  },
  created() {
    this.getMatchResDetail();
  },
  methods: {
    // 获取匹配结果详情
    async getMatchResDetail() {
      let reqDatas = {
        orderSn: this.orderSn,
        applyId: this.applyId,
      };
      let res = await API_Common.matchResult(reqDatas);
      if (res?.code === 200) {
        const datas = res.data;
        let tagTxt = "";
        datas.productTags.forEach(function (item, index) {
          if (index > 0) {
            tagTxt = tagTxt + "、" + item;
          } else {
            tagTxt = item;
          }
        });
        this.productId = datas.productId;
        this.redirectUrl = datas.redirectUrl;
        this.contractArrs = datas.contract.contracts;
        this.viewDatas = {
          realName: datas.realName,
          applyAmount: datas.applyAmount,
          companyLogo: datas.productLogo,
          companyName: datas.companyName,
          companyTags: tagTxt,
          major: datas.major,
          applyNum: datas.applyNum,
          loanNum: datas.loanNum,
          authContractDetail: datas.contract.defaultContract.contractContent,
        };
      } else {
        // 获取匹配计划结果接口异常, 跳转下一个页面
        this.gotoNextPage(false);
      }
    },

    // 提交进件申请
    async submitApply() {
      this.isPopoutShow = false;
      if (this.redirectUrl) {
        // 链接不为空, 需要跳转外链
        window.open(this.redirectUrl);
        return;
      }

      try {
        var res = await API_Common.pushApply({
          applyId: this.applyId,
          productId: this.productId,
        });
        if (res.code === 200) {
          const datas = res.data;
          this.hasPushSuccess = datas.hasPushSuccess;
          if (this.hasPushSuccess === 0) {
            // 进件失败
            this.gotoNextPage(false);
            return;
          }
          // 跳转到合同签署中页面
          this.$router.push({
            name: "ApplyWaiting",
            query: {
              source: this.form.channelNo,
              applyId: this.applyId,
              brand: this.brand,
              amount: this.form.loanAmount,
              isRedirectProductsPage: this.isRedirectProductsPage,
              isRedirectWorkWeixinPage: this.isRedirectWorkWeixinPage,
              downPageUrl: this.downPageUrl,
            },
          });
        } else {
          // 进件接口异常，跳转下个页面
          this.gotoNextPage(false);
        }
      } catch (error) {
        // 进件接口异常，跳转下个页面
        this.gotoNextPage(false);
      }
    },

    // 显示协议弹框
    handleShowPopout(title) {
      let contracts = this.contractArrs.filter(
        (item) => item.contractName === title
      );
      this.contractTitle = contracts[0].contractName;
      this.contractContent = contracts[0].contractContent;
      this.isPopoutShow = true;
    },
    // 关闭协议弹框
    handleColsePopout() {
      this.contractTitle = "";
      this.contractContent = "";
      this.isPopoutShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  text-align: center;
  background: url("../../assets/images/wateTopBg.png") no-repeat;
  background-color: #f5f5f5;
  height: 100%;
  .topbg {
    padding-top: 0.45rem;
    height: 1.23rem;
    background-size: cover;
    .noti {
      display: flex;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      background-color: rgba(255, 255, 255, 0.4);
      height: 0.68rem;
      text-align: left;
      border-radius: 10px;
      .pic {
        margin-top: 0.12rem;
        margin-left: 12px;
        margin-right: 12px;
        width: 1.33rem;
        height: 0.4rem;
        font-size: 0.24rem;
        color: #fff;
        text-align: center;
        background: url("../../assets/images/mateResultNoti.png") no-repeat;
        background-size: cover;
      }
      span {
        font-size: 0.24rem;
        color: #222222;
        line-height: 0.68rem;
      }
    }
  }
  .amountBg {
    margin-top: 0.14rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    padding-top: 0.42rem;
    height: 1.8rem;
    text-align: center;
    background: url("../../assets/images/mateResultBg.png") no-repeat;
    background-size: cover;
    .title {
      font-size: 0.26rem;
      color: #fff;
    }
    .acount {
      margin-top: 0.12rem;
      text-align: center;
      font-size: 0.6rem;
      color: #fff;
      i {
        font-size: 0.38rem;
        color: #fff;
      }
      span {
        font-size: 0.6rem;
        color: #fff;
        font-weight: 400;
      }
    }
  }
  .company {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: 0.24rem;
    // height: 4.5rem;
    padding-top: 0.32rem;
    padding-bottom: 0.75rem;
    background-color: #fff;
    border-radius: 10px;
    .title {
      font-size: 0.3rem;
      color: #222222;
      font-family: "SourceHanSansCN-Medium";
    }
    .companyInfo {
      display: flex;
      margin-left: 0.6rem;
      .left {
        position: relative;
        margin-top: 0.18rem;
        margin-right: 0.17rem;
        width: 0.87rem;
        height: 0.87rem;
        background-size: cover;
      }
      .logo {
        position: absolute;
        display: inline-block;
        width: 0.87rem;
        height: 0.87rem;
        top: 0;
        left: 0;
      }
      .right {
        margin-top: 0.18rem;
        font-size: 0.28rem;
        color: #222222;
        text-align: left;
      }
      .txt-tags {
        color: #666666;
        font-size: 0.26rem;
      }
    }
    .line {
      margin-top: 0.2rem;
      margin-left: 0.6rem;
      margin-right: 0.6rem;
      height: 0.5px;
      background-color: #d7d7d7;
    }
    .business {
      display: flex;
      justify-content: space-between;
      margin-left: 0.6rem;
      margin-right: 1.1rem;
      height: 1.24rem;
      h5 {
        font-size: 0.28rem;
        color: #fb6b62;
      }
      p {
        font-size: 0.26rem;
        color: #666666;
      }
    }
    .business-tags {
      margin-top: 0.98rem;
      font-size: 0.26rem;
      h5 {
        color: #fb6b62;
        font-size: 0.26rem;
      }
      p {
        color: #666666;
        font-size: 0.24rem;
      }
      .tags-icon {
        position: relative;
        height: 0.49rem;
        width: 0.45rem;
        top: -0.62rem;
        left: -0.4rem;
        background-size: cover;
      }
      .tags-txt {
        position: relative;
        top: -1.2rem;
        left: 0.53rem;
        text-align: left;
      }
      .uix-number {
        margin-left: 0.2rem;
      }
    }
    .descript {
      margin-left: 0.49rem;
      margin-right: 0.49rem;
      height: 1.04rem;
      font-size: 0.24rem;
      text-align: left;
      i {
        color: #fb6b62;
      }
      span {
        color: #666666;
        line-height: 0.43rem;
      }
      .descript-arrs {
        color: #fb6b62;
        span {
          color: #fb6b62;
        }
      }
    }
  }
  .procol {
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 0.2rem;
    height: 6.5rem;
    background-color: #fff;
    border-radius: 10px;
    .proDiv {
      height: calc(100% - 0.78rem);
      overflow: auto;
      margin-left: 0.42rem;
      margin-right: 0.42rem;
      padding-bottom: 1.5rem;
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      text-align: justify;
      color: #666666;
      line-height: 0.48rem;
    }
    h5 {
      font-size: 0.28rem;
      color: #202020;
    }
    p {
      margin-top: 0.4rem;
      font-size: 0.24rem;
      color: #666666;
    }
  }
  .btn {
    // position: absolute;
    position: fixed;
    // margin-top: 0.5rem;
    // margin-left: 0.55rem;
    // margin-right: 0.55rem;
    left: 0.55rem;
    right: 0.55rem;
    bottom: 0;
    z-index: 5;
    // width: 6.4rem;
    height: 1.2rem;
    .bun {
      height: 1rem;
      background-color: #fb6b62;
      font-size: 0.32rem;
      color: #ffffff;
      line-height: 1rem;
      border-radius: 0.5rem;
    }
  }
  .procolBg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    .content {
      margin-top: 3.2rem;
      margin-left: 0.6rem;
      margin-right: 0.6rem;
      padding-top: 0.5rem;
      height: 7.5rem;
      background-color: #fff;
      border-radius: 10px;
      .colse {
        position: fixed;
        right: -1.6rem;
        margin-top: -0.4rem;
        margin-right: 2.4rem;
        padding: 0 16px;
        color: #c8c9cc;
        font-size: 22px;
      }
      h5 {
        font-size: 0.28rem;
        color: #202020;
      }
      .content-dt {
        background-color: #fff;
        height: 7.5rem;
      }
      .proText {
        margin-top: 0.23rem;
        margin-left: 0.23rem;
        margin-right: 0.23rem;
        height: calc(100% - 1.78rem);
        overflow: auto;
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        text-align: justify;
        color: #666666;
        line-height: 0.48rem;
      }
      .agreeBtn {
        margin-top: 0.6rem;
        margin-left: 0.23rem;
        margin-right: 0.23rem;
        height: 0.88rem;
        font-size: 0.28rem;
        line-height: 0.88rem;
        color: #ffffff;
        background-color: #fb6b62;
        border-radius: 0.44rem;
      }
    }
  }
  .server-tips {
    font-size: 0.29rem;
    color: #fb6b62;
    font-family: "SourceHanSansCN-Medium";
    .tips-fg {
      position: relative;
      width: 17px;
      height: 4px;
      top: -0.2rem;
    }
    span {
      padding-left: 0.1rem;
      padding-right: 0.1rem;
    }
  }
  .ts-ui-x {
    padding: 0.1rem 0rem;
  }
}
</style>
